// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("../custom/absence")
require("@nathanvda/cocoon")
require("channels")
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import "bootstrap"
import "chartkick/chart.js"
require("./chartkick.js")
require("./horizontal-menu.min.js")
require("./sk-content-sidebar.min.js")
require("./navs")
require("./prism.min")
require("./unison.min.js")
require("./vertical-menu-light.min.js")
require("./bootstrap.min.js")
require("./popover-x.js")
require("./app-menu.js")
require("./app.js")
require("./noui-slider")
require("./ext-component-miscellaneous")
require("./table-extended")
require("./content-sidebar")
require("./dashboard-ecommerce")
require("./components")

require('perfect-scrollbar')
import 'trix/dist/trix.css';
import 'trix/dist/trix.js';
global.Rails = Rails;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")
